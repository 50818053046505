import { doc, getDoc, getDocFromCache } from "firebase/firestore";
import { db } from "../firebase/config";

export async function getSelectedBeursDates() {
	let selectedDates = {};

	const docRef = doc(db, "snapshots", "selectedDates");

	try {
		// cache
		const doc = await getDocFromCache(docRef);
		selectedDates = doc.data();
	} catch (e) {
		// not cached
		const doc = await getDoc(docRef);
		selectedDates = doc.data();
	}

	return selectedDates;
}

export async function getBeurzen(raw = false) {
	console.log("Retrieving beurzen data...");

	let beurzen = [];
	const date = new Date();

	const allBeurzen = [];
	const currentYear = date.getFullYear();

	for (let i = 0; i < 7; i++) {
		const docRef = doc(db, "beurzen", (currentYear + i).toString());
		const docSnapshot = await getDoc(docRef);
		const data = docSnapshot.data();

		// loop key and value of data
		if (data !== undefined) {
			for (const [key, value] of Object.entries(data)) {
				try {
					allBeurzen.push({
						id: key,
						startdate: value.sd.toDate().toLocaleDateString("en-GB"), // Convert Firestore timestamp to dd/mm/yyyy
						enddate: value.ed.toDate().toLocaleDateString("en-GB"), // Convert Firestore timestamp to dd/mm/yyyy
						title: value.tt,
						projecten: value.pr,
						inittitle: value["i-tt"],
						location: value.loc,
						source: value.src,
						img: value.img,
					});
				} catch (e) {
					console.log("Error", e);
					console.log(key, value);
				}
			}
		}
	}

	if (!raw) {
		// allBeurzen = allBeurzen.filter((obj) => {
		// 	const startDate = new Date(obj.startdate);
		// 	return startDate >= date || obj["projecten"] !== undefined;
		// });

		// allBeurzen = removeDuplicates(allBeurzen);

		const userDocument = doc(db, "users", "standbouwprinsdesignbv");

		// Fetch the document
		const userDoc = await getDoc(userDocument);
		if (!userDoc.exists()) {
			console.log("No such document!");
			return;
		}

		// Extract 'ingeschreven' array from the document data, if it doesn't exist, use an empty array
		const ingeschrevenArray = userDoc.data()["ingeschreven"] || [];

		// Initialize the 'beurzen' array to store beurzen that match the criteria
		let beurzen = [];

		// Process each 'ingeschreven' item, check if it's active and matches an 'inittitle' in 'allBeurzen'
		ingeschrevenArray.forEach((item) => {
			if (item.active) {
				allBeurzen.forEach((beurs) => {
					const beursYear = beurs.startdate.split("/")[2]; // Extract the year from 'startdate' which is in format 'dd/mm/yyyy'
					if (beurs.inittitle === item.inittitle && beursYear === item.year) {
						beurzen.push(beurs);
					}
				});
			}
		});

		beurzen = sortBeurzen(beurzen);

		// Check if all from field "projecten" which is array with maps are not 0
		beurzen = beurzen.filter((beurs) => {
			// Check if all projecten are done
			const projecten = beurs.projecten || [];
			const allProjectenDone = projecten.every((project) => project.status !== 0);

			// Check if the beurs is before today

			// Split the date string into an array [day, month, year]
			const [day, month, year] = beurs.startdate.split("/");

			// Create a new Date object (month is 0-indexed in JavaScript)
			const startDate = new Date(year, month - 1, day);

			const today = new Date();

			const isBeforeToday = startDate < today;

			// If the beurs is before today and all projecten are done, remove from beurzen
			return !(isBeforeToday && allProjectenDone);
		});

		return beurzen;
	} else {
		beurzen = allBeurzen;
		return beurzen;
	}
}

function removeDuplicates(jsonArray) {
	const uniqueTitles = {};
	return jsonArray.reduce((accumulator, currentValue) => {
		if (!uniqueTitles[currentValue.title]) {
			uniqueTitles[currentValue.title] = true;
			accumulator.push(currentValue);
		}
		return accumulator;
	}, []);
}

function sortBeurzen(beurzen) {
	return beurzen.sort((a, b) => {
		// Extract and convert dates assuming the format is "DD/MM/YYYY"
		let [dayA, monthA, yearA] = a.startdate.split("/").map(Number);
		let [dayB, monthB, yearB] = b.startdate.split("/").map(Number);

		// Create date objects
		const dateA = new Date(yearA, monthA - 1, dayA); // month is 0-indexed in JavaScript
		const dateB = new Date(yearB, monthB - 1, dayB);

		// Compare the date objects
		return dateA - dateB;
	});
}
