import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { onAuthStateChanged } from "firebase/auth";
import {
	Timestamp,
	doc,
	getDoc,
	getDocFromCache,
	updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeursList from "../components/Common/BeursList";
import CheckBoxRow from "../components/Common/CheckBoxRow";
import FileUpload from "../components/Common/FileUpload";
import Header from "../components/Common/Header";
import { useBeurzen } from "../context/BeurzenContext";
import { auth, db, storage } from "../firebase/config";
import "../styles/pages/bewerkproject.css";
import { getBeurzen } from "../utils/firestore-data";

function BewerkProject() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const projectID = queryParams.get("projectId");

	const { beurzen, setBeurzen } = useBeurzen();

	const navigate = useNavigate();

	let alreadyLoadedBeurs = false;

	const [selectedProject, setSelectedProject] = useState("");
	const [selectedBeurs, setSelectedBeurs] = useState("");

	function deleteFile(index, change) {
		const filePath = document
			.querySelectorAll(".file-upload")
			[index].getAttribute("ref");
		const desertRef = ref(storage, filePath);
		// deleteObject(desertRef).then(() => {
		if (!change) {
			document.querySelectorAll(".file-upload")[index].style.display = "flex";
			document.querySelectorAll(".file-upload")[index].children[0].value = "";
			document.querySelectorAll(".file-buttons")[index].style.display = "none";
			document.querySelectorAll(".file-upload")[index].removeAttribute("ref");
		}

		let old_element =
			document.querySelectorAll(".file-upload")[index].children[0];
		let new_element = old_element.cloneNode(true);
		old_element.parentNode.replaceChild(new_element, old_element);

		document
			.querySelectorAll(".file-upload")
			[index].children[0].addEventListener("input", () => {
				onFileUpload(document.querySelectorAll(".file-upload")[index].children[0]);
			});
		// });
	}

	async function onFileUpload(el) {
		let elementIndex = 0;
		let fileUploads = document.querySelectorAll("input[type=file]");

		for (let i = 0; i < fileUploads.length; i++) {
			if (fileUploads[i] == el) {
				elementIndex = i;
			}
		}

		// upload to firebase storage

		const file =
			document.querySelectorAll(".file-upload")[elementIndex].children[0].files[0];
		const fileName = file.name;

		const imageRef = ref(
			storage,
			`projecten/${projectID}/${String(Date.now())}_${fileName}`
		);

		uploadBytes(imageRef, file).then((snapshot) => {
			console.log("Uploaded file");
			document
				.querySelectorAll(".file-upload")
				[elementIndex].setAttribute("ref", snapshot.metadata.fullPath);

			document.querySelectorAll(".file-upload")[elementIndex].style.display =
				"none";
			document.querySelectorAll(".file-buttons")[elementIndex].style.display =
				"flex";

			let old_element =
				document.querySelectorAll(".file-upload")[elementIndex].children[0];
			let new_element = old_element.cloneNode(true);
			old_element.parentNode.replaceChild(new_element, old_element);

			document
				.querySelectorAll(".file-upload")
				[elementIndex].children[0].addEventListener("input", async () => {
					await deleteFile(elementIndex, true);
					onFileUpload(
						document.querySelectorAll(".file-upload")[elementIndex].children[0]
					);
				});
		});
	}

	let projectInitTitle;

	useEffect(() => {
		window.scrollTo(0, 0);

		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!user) {
				navigate("/login");
			}
		});

		const fetchBeurzen = async () => {
			if (beurzen.length > 0) return;

			try {
				const beurzenData = await getBeurzen();

				setBeurzen(beurzenData);
			} catch (error) {
				console.error("Failed to fetch beurzen:", error);
				navigate("/login");
			}
		};

		async function fetchProjectData() {
			if (projectID != null && !alreadyLoadedBeurs) {
				alreadyLoadedBeurs = true;

				const docRef = doc(db, "projecten", projectID);
				let projectData;

				try {
					// cache
					const doc = await getDocFromCache(docRef);
					projectData = doc.data();
				} catch (e) {
					// not cached
					const doc = await getDoc(docRef);
					projectData = doc.data();
				}

				document.getElementById("contact").value = projectData["contact"];
				document.getElementById("beursnaam").value = projectData["name"].replaceAll(
					"&amp;",
					"&"
				);
				document.getElementById("startDate").value = projectData["startDate"];
				document.getElementById("endDate").value = projectData["endDate"];
				document.getElementById("location").value = projectData["location"];
				document.getElementById("opleverdag").value = projectData["opleverdag"];
				document.getElementById("standnummer").value = projectData["standnummer"];
				document.getElementById("status").selectedIndex = projectData["status"];
				document.getElementById("projectID").value = projectID;

				setSelectedProject(projectData["contact"]);

				setSelectedBeurs(projectData["name"].replaceAll("&amp;", "&"));

				// Set detailering text
				const html = projectData["detailering"].split("\n").join("<br/>");
				const blocksFromHtml = htmlToDraft(html);
				const { contentBlocks, entityMap } = blocksFromHtml;

				// Creating content state from the blocks and entity map obtained from the HTML.
				const contentState = ContentState.createFromBlockArray(
					contentBlocks,
					entityMap
				);

				// Creating an EditorState with the content obtained and updating the editor's state.
				const editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState);

				let checkboxData = projectData["checkboxes"];
				let noteData = projectData["notes"];
				let fileData = projectData["files"];

				let tableTrs = document.querySelectorAll("table:nth-child(1) > tbody > tr");

				for (let i = 0; i < tableTrs.length; i++) {
					if (tableTrs[i].children[0].className != "collapsible") {
						const checkboxVal = checkboxData[tableTrs[i].children[0].textContent];

						tableTrs[i].children[1].children[0].children[0].checked = false;
						tableTrs[i].children[1].children[0].children[2].checked = false;
						tableTrs[i].children[1].children[0].children[4].checked = false;

						if (checkboxVal == undefined) {
							tableTrs[i].children[1].children[0].children[0].checked = true;
						}

						if (checkboxVal == 0) {
							tableTrs[i].children[1].children[0].children[0].checked = true;
						}
						if (checkboxVal == 1) {
							tableTrs[i].children[1].children[0].children[2].checked = true;
						}
						if (checkboxVal == 2) {
							tableTrs[i].children[1].children[0].children[4].checked = true;
						}

						if (
							noteData[tableTrs[i].children[0].textContent] != undefined &&
							tableTrs[i].children.length > 3
						) {
							tableTrs[i].children[3].children[0].value =
								noteData[tableTrs[i].children[0].textContent];
						}

						if (fileData[tableTrs[i].children[0].textContent] != undefined) {
							tableTrs[i].children[2].children[0].setAttribute(
								"ref",
								fileData[tableTrs[i].children[0].textContent]
							);

							tableTrs[i].children[2].children[0].style.display = "none";
							tableTrs[i].children[2].children[1].style.display = "flex";

							let old_element = tableTrs[i].children[2].children[0].children[0];
							let new_element = old_element.cloneNode(true);

							old_element.parentNode.replaceChild(new_element, old_element);

							tableTrs[i].children[2].children[0].children[0].addEventListener(
								"input",
								async () => {
									await deleteFile(i, true);
									onFileUpload(tableTrs[i].children[2].children[0].children[0]);
								}
							);
						} else {
							try {
								tableTrs[i].children[2].children[0].removeAttribute("ref");

								tableTrs[i].children[2].children[0].style.display = "flex";
								tableTrs[i].children[2].children[1].style.display = "none";

								let old_element = tableTrs[i].children[2].children[0].children[0];
								let new_element = old_element.cloneNode(true);

								old_element.parentNode.replaceChild(new_element, old_element);

								tableTrs[i].children[2].children[0].children[0].addEventListener(
									"input",
									async () => {
										onFileUpload(tableTrs[i].children[2].children[0].children[0]);
									}
								);
							} catch (e) {
								//
							}
						}
					}
				}
			}
		}

		fetchBeurzen();
		fetchProjectData();

		return () => {
			unsubscribe();
		};
	}, [navigate, projectID]);

	function formatDate(inputDate) {
		// Create a new Date object
		var date = new Date(inputDate);

		// Get the day, month, and year
		var day = String(date.getDate()).padStart(2, "0");
		var month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
		var year = date.getFullYear();

		// Return the formatted string
		return day + "/" + month + "/" + year;
	}

	async function submitForm() {
		const contactVal = document.getElementById("contact").value;
		const nameVal = document.getElementById("beursnaam").value;
		const startDateVal = document.getElementById("startDate").value;
		const endDateVal = document.getElementById("endDate").value;
		const locationVal = document.getElementById("location").value;
		const standnummerVal = document.getElementById("standnummer").value;
		const opleverdagVal = document.getElementById("opleverdag").value;
		const statusVal = document.getElementById("status").selectedIndex;

		// Get detailering content
		const contentState = editorState.getCurrentContent();
		const detaileringVal = draftToHtml(convertToRaw(contentState));

		let checkboxMapping = {};
		let filesMapping = {};
		let notesMapping = {};

		if (contactVal == "") {
			createAlert("Vul een contact in", false);
			return;
		} else if (nameVal == "") {
			createAlert("Vul een beurs in", false);
			return;
		} else if (startDateVal == "") {
			createAlert("Vul een start datum in", false);
			return;
		} else if (endDateVal == "") {
			createAlert("Vul een eind datum in", false);
			return;
		}
		// } else if (locationVal == "") {
		// 	createAlert("Vul een locatie in", false);
		// 	return;
		// }

		const id = toast.loading("Project wordt opgeslagen", {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			transition: Bounce,
		});

		document.querySelectorAll(`table:nth-child(1) > tbody > tr`).forEach((e) => {
			if (
				(e.childElementCount == 4 || e.childElementCount == 3) &&
				(e.children[2].childElementCount == 2 ||
					e.children[2].childElementCount == 1)
			) {
				const uploadPresent = e.children[2].childElementCount == 2;
				let selected = 0;

				if (e.children[1].children[0].children[2].checked) {
					selected = 1;
				} else if (e.children[1].children[0].children[4].checked) {
					selected = 2;
				}

				if (uploadPresent) {
					filesMapping[e.children[0].innerHTML] =
						e.children[2].children[0].getAttribute("ref");
				} else {
					filesMapping[e.children[0].innerHTML] = null;
				}

				checkboxMapping[e.children[0].innerHTML] = selected;

				if (uploadPresent) {
					notesMapping[e.children[0].innerHTML] = e.children[3].children[0].value;
				} else {
					notesMapping[e.children[0].innerHTML] = e.children[2].children[0].value;
				}
			}
		});

		const projectID = document.getElementById("projectID").value;

		await updateDoc(doc(db, "projecten", projectID), {
			contact: contactVal,
			name: nameVal,
			startDate: startDateVal,
			endDate: endDateVal,
			location: locationVal,
			standnummer: standnummerVal,
			opleverdag: opleverdagVal,
			status: statusVal,
			detailering: detaileringVal,

			checkboxes: checkboxMapping,
			files: filesMapping,
			notes: notesMapping,
		});

		// get current beurzen
		const date = new Date();
		const currentYear = date.getFullYear();
		const beursDate = new Date(startDateVal);
		const beursJaar = beursDate.getFullYear().toString();
		let beursId;
		let bewerkBeurzenData;
		let foundBeurs = false;

		console.log(projectID);

		for (let i = 0; i < 7; i++) {
			if (!foundBeurs) {
				const docRef = doc(db, "beurzen", (currentYear + i).toString());
				const docSnapshot = await getDoc(docRef);
				const data = docSnapshot.data();

				// loop key and value of data
				if (data !== undefined) {
					for (const [key, value] of Object.entries(data)) {
						if (!foundBeurs) {
							const projecten = value["pr"];
							if (projecten != undefined) {
								for (let j = 0; j < projecten.length; j++) {
									console.log(projecten[j]["id"]);
									if (projecten[j]["id"] == projectID) {
										beursId = key;
										bewerkBeurzenData = data[key];
										foundBeurs = true;
									}
								}
							}
						}
					}
				}
			}
		}

		const docRef = doc(db, "beurzen", beursJaar);

		let currentProjects = [];

		try {
			currentProjects = bewerkBeurzenData["pr"];
		} catch (e) {
			//
		}
		for (let i = 0; i < currentProjects.length; i++) {
			if (bewerkBeurzenData["id"] == projectID) {
				bewerkBeurzenData["naam"] = contactVal;
				bewerkBeurzenData["status"] = statusVal;
			}
		}

		console.log(beursId);

		bewerkBeurzenData["pr"] = currentProjects;
		bewerkBeurzenData["sd"] = Timestamp.fromDate(new Date(startDateVal));
		bewerkBeurzenData["ed"] = Timestamp.fromDate(new Date(endDateVal));
		bewerkBeurzenData["loc"] = locationVal;

		console.log(bewerkBeurzenData);

		// update entry of beursId
		await updateDoc(docRef, {
			[beursId]: bewerkBeurzenData,
		});

		const beurzenData = await getBeurzen();

		setBeurzen(beurzenData);

		toast.update(id, {
			render: "Succesvol opgeslagen!",
			type: "success",
			isLoading: false,
		});

		setTimeout(() => {
			toast.dismiss(id);
		}, 2000);
	}

	const invulvelden = {
		Startfase: [
			"Plattegrond (puttenplan)",
			"Op- en afbouwtijden",
			"Bouwregels",
			"Inlogcodes",
			"Briefing and brandguide",
		],
		"Productie en voorbereiding": [
			"Risk assessment en method",
			"Standkeuring",
			"Op- en afbouwpassen",
			"Tijdsloten en inrijkaart",
			"Systeemstand",
			"Elektra",
			"Tuien en takels",
			"Water aan- en afvoer",
			"Vloer",
			"Meubels",
			"Verlichting",
			"Audio en Visueel",
			"Grafisch werk",
			"Content",
		],
		Facturatie: ["1e termijn", "2e termijn"],
		Ontwerpfase: [
			"Def ontwerp",
			"Def offerte",
			"Def tekening",
			"Opdrachtbevestiging",
		],
		Evaluatie: ["Klant", "Beurs", "Locatie", "Diversen"],
	};

	let indexCount = 0;

	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(convertFromHTML(""))
		)
	);

	const printDetailering = () => {
		const contentState = editorState.getCurrentContent();
		const html = draftToHtml(convertToRaw(contentState));

		// Create a new jsPDF instance
		const pdf = new jsPDF("p", "pt", "a4", false);

		// Add HTML content to PDF
		pdf.html(`<div style="width:${800}px;">${html}</div>`, {
			callback: function (doc) {
				const pdfBlob = doc.output("blob");
				const pdfUrl = URL.createObjectURL(pdfBlob);
				window.open(pdfUrl, "_blank");
			},
			margin: [20, 20, 20, 20],
			x: 20,
			y: 20,
			html2canvas: { scale: 0.7 },
			autoPaging: "text",
		});
	};

	return (
		<div id="bewerk-project">
			<Header title={"Bewerk project"} />
			<main>
				<column>
					<row id="main-row">
						<BeursList
							beurzen={beurzen}
							selectedBeurs={selectedBeurs}
							selectedProject={selectedProject}
						></BeursList>
						<column id="main-right">
							<form>
								<input type="hidden" id="projectID" />

								<row>
									<column id="input-column">
										<label id="big-height" class="required">
											Contact
										</label>
										<input placeholder="Vul contact in" id="contact" required=""></input>
										<row class="inputs">
											<column>
												<label class="required">
													Beursnaam
													<autocomplete>
														{" "}
														<suggestion class="focus">Fespa Global Print Expo</suggestion>
														<suggestion>Aqua Nederland</suggestion>
														<suggestion>Beurs eigen huis voorjaar</suggestion>
														<suggestion>workspace</suggestion>
														<suggestion>Zorg &amp; food</suggestion>
														<suggestion>FireSafety &amp; Security Event</suggestion>
														<suggestion>FIBO</suggestion>
														<suggestion>wac congress</suggestion>
														<suggestion>World hydrogen congress</suggestion>
														<suggestion>gezond &amp; zeker</suggestion>
														<suggestion>Efort Congress</suggestion>
														<suggestion>podcastshow</suggestion>
														<suggestion>PLMA</suggestion>
														<suggestion>Maritime industry</suggestion>
														<suggestion>Greentech</suggestion>
														<suggestion>Provada</suggestion>
														<suggestion>Intersolar</suggestion>
														<suggestion>EUROBIKE</suggestion>
														<suggestion>cigre paris</suggestion>
														<suggestion>SMM</suggestion>
														<suggestion>Ssiem congres porto</suggestion>
														<suggestion>Maison &amp; objet</suggestion>
														<suggestion>Galabau</suggestion>
														<suggestion>Ibc</suggestion>
														<suggestion>Iaa hannover</suggestion>
														<suggestion>Kunststoffenbeurs</suggestion>
														<suggestion>WINDENERGY HAMBURG</suggestion>
														<suggestion>Vakbeurs openbare ruimte</suggestion>
														<suggestion>Onderwijsbeurs Zuid</suggestion>
														<suggestion>Vakbeurs foodspecialiteiten</suggestion>
														<suggestion>Vt wonen &amp;designbeurs</suggestion>
														<suggestion>Onderwijsbeurs Noordoost</suggestion>
														<suggestion>expopharm</suggestion>
														<suggestion>Beurs eigen huis najaar</suggestion>
														<suggestion>Prefab</suggestion>
														<suggestion>EANM congress</suggestion>
														<suggestion>Prowood</suggestion>
														<suggestion>Expovet</suggestion>
														<suggestion>Meubelbeurs Brussel</suggestion>
														<suggestion>Contacta 2024</suggestion>
														<suggestion>FILTECH</suggestion>
														<suggestion>Horeca Expo</suggestion>
														<suggestion>Fi Europe </suggestion>
														<suggestion>Studiekeuzebeurs west</suggestion>
													</autocomplete>
												</label>
												<input
													class="autocomplete"
													placeholder="Vul beursnaam in"
													id="beursnaam"
													autocomplete="off"
													required=""
												></input>
											</column>
											<column>
												<label class="required">Locatie</label>
												<input
													placeholder="Vul Locatie in"
													id="location"
													required=""
												></input>
											</column>
										</row>

										<row class="inputs">
											<column>
												<label class="required">Beurs begin datum</label>
												<input
													placeholder="Vul begin datum in"
													type="date"
													id="startDate"
													required=""
												></input>
											</column>

											<column>
												<label class="required">Beurs eind datum</label>
												<input
													placeholder="Vul eind datum in"
													type="date"
													id="endDate"
													required=""
												></input>
											</column>
										</row>

										<row class="inputs">
											<column>
												<label class="required">Opleverdag</label>
												<input
													placeholder="Vul Opleverdag in"
													type="date"
													id="opleverdag"
													required=""
												></input>
											</column>

											<column>
												<label class="required">Hal/standnummer</label>
												<input
													placeholder="Vul hal of standnummer in"
													id="standnummer"
													required=""
												></input>
											</column>
										</row>

										<label class="required">Status</label>
										<stack>
											<select
												name="status"
												id="status"
												placeholder="Vul Status in"
												onmousedown="selectOnClick(this)"
												required=""
											>
												<option value="Onderhanden">Onderhanden</option>
												<option value="Opgeleverd">Opgeleverd</option>
												<option value="Verloren">Verloren</option>
											</select>
											<img
												src="assets/chevron-right.svg"
												height="13px"
												width="13px"
												class="overlay"
											></img>
										</stack>
									</column>
									<column class="list margin-top">
										<item class="title">Geschiedenis</item>
										<p>Geen geschiedenis beschikbaar.</p>
									</column>
									<column class="list margin-top">
										<item class="title">Bijzonderheden</item>
										<item>Geen Bijzonderheden</item>
									</column>
								</row>
							</form>
							<row>
								<column id="invulvelden">
									<table>
										<tbody>
											{Object.keys(invulvelden).map((veldKey, index) => {
												const velden = invulvelden[veldKey];

												return Array.from({ length: velden.length + 1 }, (_, index2) => {
													if (index2 == 0) {
														return (
															<tr>
																<td class="collapsible">{veldKey}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														);
													} else {
														indexCount += 1;
														if (veldKey == "Evaluatie") {
															return (
																<tr class={`c-${index2}`}>
																	<td>{velden[index2 - 1]}</td>
																	<CheckBoxRow></CheckBoxRow>
																	<td>
																		<input
																			type="value"
																			class="long"
																			key={`c1-${projectID} ${index2 - 1}`}
																		></input>
																	</td>
																</tr>
															);
														} else {
															return (
																<tr class={`c-${index2 - 1}`}>
																	<td>{velden[index2 - 1]}</td>
																	<CheckBoxRow></CheckBoxRow>
																	<FileUpload
																		projectID={projectID}
																		index={indexCount - 1}
																		key={`c2${projectID} ${indexCount} ${index2 - 1}`}
																	></FileUpload>
																	<td>
																		<input
																			type="value"
																			key={`c3-${projectID} ${index2 - 1}`}
																		></input>
																	</td>
																</tr>
															);
														}
													}
												});
											})}
										</tbody>
									</table>
								</column>
								<column class="list detailering">
									<item class="title">Detailering</item>
									<img
										src="assets/print.svg"
										height="20px"
										width="20px"
										id="detailering-print"
										onClick={() => {
											printDetailering();
										}}
									></img>
									<div id="detailering">
										<Editor
											wrapperClassName="wrapper-class"
											editorClassName="editor-class"
											toolbarClassName="toolbar-class"
											editorState={editorState}
											onEditorStateChange={setEditorState}
											toolbar={{
												inline: { inDropdown: true },
												list: { inDropdown: true },
												textAlign: { inDropdown: true },
												link: { inDropdown: true },
												history: { inDropdown: true },
											}}
										/>
									</div>
								</column>
							</row>
							<buttons>
								<button id="save-button" onClick={submitForm}>
									<img
										src="assets/save-solid.svg"
										height="14px"
										width="14px"
										id="save-icon"
									></img>
									Opslaan
								</button>
							</buttons>
						</column>
					</row>
				</column>
			</main>
		</div>
	);
}

export default BewerkProject;
