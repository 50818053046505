import { signOut } from "firebase/auth";
import { motion } from "framer-motion";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { useAuth } from "../../context/AuthContext";
import { auth } from "../../firebase/config";
import "../../styles/components/header.css";

function Header({ title }) {
	async function logOutUser() {
		await signOut(auth);
	}
	const navigate = useNavigate();
	const { profile } = useAuth();

	const profileImgUrl = profile.img;
	const profileEmail = profile.email;
	const profileName = profile.username;

	const [profilePopupActive, setProfilePopupActive] = useState(false);

	function toggleProfilePopup() {
		setProfilePopupActive(!profilePopupActive);
	}

	// if click anywhere outside of the profile popup, close it
	window.addEventListener("click", (e) => {
		if (e.target.id !== "profile-popup" && e.target.id !== "logout") {
			setProfilePopupActive(false);
		}
	});

	return (
		<header>
			<nav>
				<left>
					<img src={logo} id="logo"></img>
					{title}
				</left>
				<right>
					<nav-button
						className="nav-button"
						onClick={() => {
							navigate("/planning");
						}}
						id="planning"
					>
						Planning
					</nav-button>
					<nav-button
						className="nav-button"
						onClick={() => {
							navigate("/nieuwproject");
						}}
						id="nieuwproject"
					>
						Nieuw project
					</nav-button>
					<nav-button
						className="nav-button"
						onClick={toggleProfilePopup}
						id="logout"
					>
						Profile
					</nav-button>
				</right>
			</nav>
			{profilePopupActive && (
				<motion.div
					animate={{
						y: 0,
						opacity: 1,
						transition: { duration: 0.2, type: "easeOut" },
					}}
					initial={{ y: -30, opacity: 0 }}
					id="profile-popup"
				>
					<img
						src="https://firebasestorage.googleapis.com/v0/b/beurs-agenda.appspot.com/o/standbouwprins.jpg?alt=media&token=05316d6c-69e8-4282-990a-0d08961d877b"
						id="profile-img"
					></img>
					<motion.div
						animate={{
							y: 0,
							opacity: 1,
							transition: { duration: 0.2, type: "easeOut", delay: 0.075 },
						}}
						initial={{ y: -8, opacity: 0 }}
						id="profile-welcome"
					>
						Welkom, {profileName}!
					</motion.div>
					<motion.div
						animate={{
							y: 0,
							opacity: 1,
							transition: { duration: 0.2, type: "easeOut", delay: 0.15 },
						}}
						initial={{ y: -8, opacity: 0 }}
						id="profile-email"
					>
						{profileEmail}
					</motion.div>
					<motion.button
						animate={{
							y: 0,
							opacity: 1,
							transition: { duration: 0.2, type: "easeOut", delay: 0.3 },
						}}
						initial={{ y: -8, opacity: 0 }}
						id="logout-button"
						onClick={() => {
							logOutUser();
							navigate("/");
						}}
					>
						Log uit
					</motion.button>
				</motion.div>
			)}
		</header>
	);
}

export default Header;
